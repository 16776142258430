if (!('toJSON' in Error.prototype)) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(Error.prototype, 'toJSON', {
		value() {
			const alt = {};

			Object.getOwnPropertyNames(this).forEach((key) => {
				alt[key] = this[key];
			});

			return alt;
		},
		configurable: true,
		writable: true,
	});
}
