import {appReady as coreKeeperAppReady, init as initKeeper} from '@mail-core/dashboard/keeper';
import * as coreLogger from '@mail-core/logger/inline/logger';

import {AuthMailResponseData} from '../interfaces/Auth';
import {XraySendParams, XraySendParamsRlogMessageObject} from '../interfaces/Xray';
import Auth from './Auth';
import MailBox from './MailBox';
import OAuth from './OAuth';
import Xray from './Xray';

/** @ignore */
const xrayService: Xray = new Xray();
/** @ignore */
const authService: Auth = new Auth();
/** @ignore */
const oauthService: OAuth = new OAuth(xrayService);
/** @ignore */
const mailBoxService: MailBox = new MailBox(oauthService);

/**
 * Основной класс
 *
 * Экземпляр экспортируется в **window.promokit**
 */
export default class Promokit {
	public authService = authService;

	public oauthService = oauthService;

	public mailBoxService = mailBoxService;

	public xrayService = xrayService;

	/** @ignore */
	constructor() {
		const xray = xrayService.xray;

		try {
			initKeeper({xray});
			coreKeeperAppReady();
		} catch (error) {
			coreKeeperAppReady(error);
		}

		try {
			coreLogger.init({
				xray,
				errors: {
					preset: 'promokit',
					silent: !!window.LOGGER_ERRORS_SILENT,
					logByDefault: !!window.LOGGER_ERRORS_LOG,
				},
				checkJSON: false,
			});

			// Ассинхронный «пресет» обновляет используя глобальный объект логгера
			self['coreLogger'] = coreLogger;
		} catch {
			// Игнорируемую ошибку
		}

		xray.send('app_ready');
	}

	// https://gitlab.corp.mail.ru/mail/mrg-xray
	/**
	 * См. {@link Xray.sendRadar}
	 *
	 * @example
	 * ```js
	 * // Отправка радара
	 * promokit.sendRadar('show', {
	 * 	i: 'main-page'
	 * });
	 * ```
	 *
	 * @example
	 * ```js
	 * // Отправка радара
	 * promokit.sendRadar('show_main-page');
	 * ```
	 *
	 * @param name Имя метрики
	 * @param params Параметры
	 */
	sendRadar(name: string, params: XraySendParams) {
		return xrayService.sendRadar(name, params);
	}

	// https://gitlab.corp.mail.ru/mail/mrg-xray
	/**
	 * См. {@link Xray.sendRadarImmediately}
	 *
	 * @example
	 * ```js
	 * // Отправка радара незамедлительно
	 * promokit.sendRadarImmediately('redirect', {
	 * 	i: 'login'
	 * });
	 * ```
	 *
	 * @example
	 * ```js
	 * // Отправка радара незамедлительно
	 * promokit.sendRadarImmediately('redirect_login');
	 * ```
	 *
	 * @param name Имя метрики
	 * @param params Параметры
	 */
	sendRadarImmediately(name: string, params: XraySendParams) {
		return xrayService.sendRadarImmediately(name, params);
	}

	// https://gitlab.corp.mail.ru/mail/mrg-xray
	/**
	 * Отправить радар с данными в лог
	 *
	 * @example
	 * ```js
	 * // Отправка радара с данными в лог
	 * promokit.sendToRlog('log_name', {
	 * 	error: 'error message'
	 * });
	 * ```
	 *
	 * @param name Имя лога и метрики
	 * @param data Данные
	 */
	sendToRlog(name: string, data: XraySendParamsRlogMessageObject): void {
		const xrayConfig = xrayService.xray.getConfig();
		return xrayService.sendRadarImmediately(name, {
			rlog: `${xrayConfig.defaultParams.p}_${name}`,
			rlog_message: data,
		});
	}

	/**
	 * См. {@link Auth.getActiveUser}
	 *
	 * @example
	 * ```js
	 * // Получить email и имя пользователя или проверить, что пользователь авторизован
	 * promokit.getActiveUser() // работает только на доменах *.mail.ru
	 *	.then(function (data) { // пользователь авторизован
	 *		console.log('Данные пользователя:', data);
	 *	})
	 *	.catch(function (error) {
	 *		if (error === 'noauth') {
	 *			console.log('Пользователь не авторизован');
	 *		} else {
	 *			console.log('Ошибка:', error);
	 *		}
	 *	});
	 * ```
	 *
	 * @example
	 * ```js
	 * // Если пользователь авторизован - открыть форму написания письма с параметрами, если нет - показать форму логина
	 * var url = promokit.mailBoxService.getComposeUrl({
	 * 	to: 'test@mail.ru',
	 * 	subject: 'Поздравляю с 8 Марта!',
	 * 	body: 'Поздравляю с праздником!\n' +
	 * 		'\n' +
	 * 		'Вместо тысячи слов дарю этот видеоролик: \n' +
	 * 		'http://8march.mail.ru/ext/y7tg7795\n' +
	 * 		'\n' +
	 * 		'Счастья и любви!'
	 * });
	 *
	 * if (location.search.match(/[?&]authid=/)) {
	 * 	location.href = url;
	 * } else {
	 * 	promokit.getActiveUser() // работает только на доменах *.mail.ru
	 * 		.then(function (data) { // пользователь авторизован
	 * 			console.log('Данные пользователя:', data);
	 *
	 * 			location.href = url;
	 * 		})
	 * 		.catch(function () {
	 * 			// Если не передан параметр success_redirect, то авторизация вернёт на эту же страницу с гет-параметром authid
	 * 			promokit.authService.showForm();
	 * 		});
	 * }
	 * ```
	 */
	getActiveUser(): Promise<AuthMailResponseData> {
		return authService.getActiveUser();
	}
}
