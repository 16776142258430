import xray from '@mail/xray';

import {AuthGateLoadData, AuthGateParams, AuthMailResponseData} from '../interfaces/Auth';
import {AUTHGATE_LIB_URL} from '../utils/constants';
import {getActiveUser, isMobile, loadScript} from '../utils/helpers';

/** @ignore */
let authGate = null;

/**
 * Сервис для работы с авторизацией
 */
export default class Auth {
	// https://gitlab.corp.mail.ru/account/auth.gate#readme
	/**
	 * Загрузить и получить ссылку на библиотеку AuthGate
	 *
	 * @example
	 * ```js
	 * // Загрузить и получить ссылку на библиотеку AuthGate
	 * promokit.authService.getAuthGate()
	 * 	.then(function (data) {
	 * 		console.log('Библиотека:', data.authGate);
	 * 	})
	 * 	.catch(function (error) {
	 * 		console.log('Ошибка:', error);
	 * 	});
	 * ```
	 */
	getAuthGate(): Promise<AuthGateLoadData> {
		if (authGate) {
			return Promise.resolve({authGate});
		}

		return loadScript(AUTHGATE_LIB_URL).then(() => {
			if (window.__AG) {
				return new Promise((resolve) => {
					window.__AG.then(() => {
						authGate = window.__AG;

						resolve({authGate});
					});
				});
			}

			xray.send('authgate', {
				i: 'load_error',
			});

			throw 'load_error';
		});
	}

	/**
	 * Получить email и имя пользователя или проверить, что пользователь авторизован<br/>
	 * Работает только на доменах *.mail.ru<br/>
	 * Как альтернативу можно использовать promokit.oauthService.api('golang/user/short')
	 *
	 * @example
	 * ```js
	 * // Получить email и имя пользователя или проверить, что пользователь авторизован
	 * promokit.authService.getActiveUser()
	 *	.then(function (data) { // пользователь авторизован
	 *		console.log('Данные пользователя:', data);
	 *	})
	 *	.catch(function (error) {
	 *		if (error === 'noauth') {
	 *			console.log('Пользователь не авторизован');
	 *		} else {
	 *			console.log('Ошибка:', error);
	 *		}
	 *	});
	 * ```
	 */
	getActiveUser(): Promise<AuthMailResponseData> {
		return getActiveUser();
	}

	/**
	 * Отправить пользователя на страницу логина
	 *
	 * @example
	 * ```js
	 * // Открыть страницу логина
	 * promokit.authService.openLogin({
	 * 	allow_external: true
	 * });
	 * ```
	 *
	 * @param params Настройки
	 */
	openLogin(params: Partial<AuthGateParams> = {}): void {
		this.getAuthGate().then(({authGate}) => {
			xray.send('authgate', {
				i: 'open_login',
			});

			authGate.show({
				...params,
				redirect_to_login: true,
			});
		});
	}

	/**
	 * Показать форму логина
	 *
	 * @example
	 * ```js
	 * // Показать форму логина
	 * promokit.authService.showForm();
	 * ```
	 *
	 * @example
	 * ```js
	 * // Показать форму логина c параметрами
	 * promokit.authService.showForm({
	 * 	allow_external: true
	 * });
	 * ```
	 *
	 * @param params Настройки
	 */
	showForm(params: Partial<AuthGateParams> = {}): void {
		if (isMobile()) {
			this.openLogin(params);
		} else {
			this.getAuthGate().then(({authGate}) => {
				xray.send('authgate', {
					i: 'show_form',
				});

				authGate.show(params);
			});
		}
	}
}
