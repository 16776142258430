/// <reference path="types/globals.d.ts" />
/// <reference path="../node_modules/@mail-core/dashboard/keeper/navigator.d.ts" />

// https://github.com/kevlatus/polyfill-array-includes#readme
import 'polyfill-array-includes';
// https://github.com/alfaslash/string-includes-polyfill#readme
import 'string-includes-polyfill';
// https://github.com/taylorhakes/promise-polyfill#readme
import 'promise-polyfill/src/polyfill';
// https://github.com/github/fetch#readme
import 'whatwg-fetch';
import './utils/errorToJSON';

import Promokit from './classes/Promokit';

/** @ignore */
// eslint-disable-next-line init-declarations
export declare const promokit: Promokit;

/** @ignore */
export interface Window {
	promokit: Promokit;
}

export default new Promokit();
