import {api as coreKeeperAPI} from '@mail-core/dashboard/keeper';
import {now} from '@mail-core/dashboard/keeper/binding/util.now';
import {InitOptions} from '@mail/oauth';
import xray from '@mail/xray';

import {OAuthLoadData} from '../interfaces/OAuth';
import {CORSAPI_ORIGIN, OAUTH_LIB_URL} from '../utils/constants';
import {loadScript} from '../utils/helpers';

/** @ignore */
function objectToFormData(data) {
	const formData = new FormData();

	for (const key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			if (typeof data[key] === 'object') {
				formData.append(key, JSON.stringify(data[key]));
			} else {
				formData.append(key, data[key]);
			}
		}
	}

	return formData;
}

/** @ignore */
function coreKeeperAPIHelper(defUrl: string) {
	const startTime = now();

	return (apiStatus: number, httpStatus = 200, url = defUrl) => {
		coreKeeperAPI(
			{
				url,
				status: httpStatus,
				statusText: 'OK',
				readyState: XMLHttpRequest.DONE,
			},
			{
				apiStatus,
				duration: now() - startTime,
			},
		);
	};
}

/** @ignore */
let OAuthAccessToken: string = null;

/** @ignore */
let oauth = null;

/**
 * Сервис для работы с OAuth
 */
export default class OAuth {
	/** @ignore */
	constructor(private xrayService) {}

	private getAccessToken(): Promise<any> {
		if (OAuthAccessToken) {
			return Promise.resolve(OAuthAccessToken);
		}
		const apiComplete = coreKeeperAPIHelper('oauth/auth');

		return this.getOAuth().then(({oauth}) => {
			return new Promise((resolve, reject) => {
				const timeoutId = setTimeout(() => {
					apiComplete(408); // Отвалились по таймауту
					xray.send('oauth', {
						i: 'auth_timeout',
					});

					reject('timeout');
				}, 5000);

				oauth.auth((state) => {
					apiComplete(200);
					apiComplete(200, 200, `oauth/auth-${state.status}`); // И отдельно каккой «статус» вернули

					xray.send('oauth', {
						i: `status_${state.status}`,
					});

					clearTimeout(timeoutId);

					if (state.status === oauth.AUTH_CONNECTED) {
						resolve((OAuthAccessToken = state.access_token));
					} else {
						reject('noauth');
					}
				});
			});
		});
	}

	/**
	 * Загрузить и получить ссылку на библиотеку OAuth
	 *
	 * @example
	 * ```js
	 * // Загрузить и получить ссылку на библиотеку OAuth
	 * promokit.oauthService.getOAuth()
	 * 	.then(function (data) {
	 * 		console.log('Библиотека:', data.oauth);
	 * 	})
	 * 	.catch(function (error) {
	 * 		console.log('Ошибка:', error);
	 * 	});
	 * ```
	 */
	getOAuth(): Promise<OAuthLoadData> {
		if (oauth) {
			return Promise.resolve({oauth});
		}
		const url = 'oauth/load';
		const apiComplete = coreKeeperAPIHelper(url);

		if (window.OAUTH_CLIENT_ID) {
			return loadScript(OAUTH_LIB_URL).then(() => {
				if (window.MR) {
					oauth = window.MR;

					const oauthOptions: InitOptions = {
						clientId: window.OAUTH_CLIENT_ID,
					};

					oauth.init(oauthOptions);
					apiComplete(200);

					return {oauth};
				}
				apiComplete(503); // Скрипт загружен, но MR-объекта нет

				xray.send('oauth', {
					i: 'load_error',
				});

				throw 'load_error';
			});
		}

		apiComplete(406); // Клиент не сконфигурен

		xray.send('oauth', {
			i: 'empty_client_id',
		});

		return Promise.reject('window.OAUTH_CLIENT_ID required');
	}

	/**
	 * Список возможных scope
	 *
	 * mail.api.nosecstep
	 * mail.api.secstep
	 * mail.api.year_stat
	 * mail.api.helpers
	 * mail.api.messages-send-test-amp
	 * mail.api.attaches
	 * mail.api.folders
	 * mail.api.user.short
	 * mail.api.messages.count.all
	 */

	/**
	 * Сделать запрос к серверу
	 *
	 * @example
	 * ```js
	 * // Получить список хелперов
	 * promokit.oauthService.api('helpers')
	 *	.then(function (helpers) {
	 *		console.log('Хелперы:', helpers);
	 *	})
	 *	.catch(function (error) {
	 *		if (error === 'noauth') {
	 *			console.log('Пользователь не авторизован');
	 *		} else {
	 *			console.log('Ошибка:', error);
	 *		}
	 *	});
	 * ```
	 *
	 * @example
	 * ```js
	 * // Получить данные профиля пользователя
	 * promokit.oauthService.api('golang/user/short')
	 *	.then(function (user) {
	 *		console.log('Данные пользователя:', user);
	 *	})
	 *	.catch(function (error) {
	 *		if (error === 'noauth') {
	 *			console.log('Пользователь не авторизован');
	 *		} else {
	 *			console.log('Ошибка:', error);
	 *		}
	 *	});
	 * ```
	 *
	 * @param method Метод АПИ
	 * @param params Параметры
	 * @param retryCount Служебный параметр
	 */
	api(method: string, params = {}, retryCount = 1): Promise<any> {
		return this.getAccessToken().then((token) => {
			const url = `${CORSAPI_ORIGIN}/api/v1/${method}`;
			const apiComplete = coreKeeperAPIHelper(url);
			const formData = params instanceof FormData ? params : objectToFormData(params);

			formData.append('access_token', token);
			formData.append('htmlencoded', 'false');

			// eslint-disable-next-line compat/compat
			return fetch(url, {
				method: 'POST',
				credentials: 'omit',
				body: formData,
			})
				.then((response) => {
					if (!response.ok) {
						// Логируем все не HTTP 2XX
						apiComplete(0, response.status);
					}
					return response.json();
				})
				.then((response) => {
					apiComplete(response.status); // HTTP OK, Логируем API STatus

					xray.send('oauth-api', {
						i: `${method.replace(/\//g, '_')}_${response.status}`,
					});

					if (response.status === 200) {
						return response.body;
					} else if (response.status === 403) {
						if (retryCount-- > 0) {
							OAuthAccessToken = null;

							return this.api(method, params, retryCount);
						}
						apiComplete(403, 200, `${url}-retry`); // Кончились повторы
						this.xrayService.sendToRlog('oauth-api-error', response);

						throw response;
					} else {
						this.xrayService.sendToRlog('oauth-api-error', response);

						throw response;
					}
				});
		});
	}
}
